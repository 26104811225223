import { useState } from "react"
import type { ComponentType } from "react"

export function setFullWidth(Component): ComponentType {
    return (props) => {
        return (
            <>
                <Component {...props} />
                <style>{`
          .${props.className} img {
            width: 100%; /* Set image width to 100% of the parent container */
          }
        `}</style>
            </>
        )
    }
}

export function setFullWidthRadius(Component): ComponentType {
    return (props) => {
        return (
            <>
                <Component {...props} />
                <style>{`
          .${props.className} img {
            width: 100%; /* Set image width to 100% of the parent container */
            border-radius: 12px; /* Added 12px border radius */
          }
        `}</style>
            </>
        )
    }
}
